.icon-source {
  max-width: 24px;
  max-height: 24px;
}

/* for KeepAlive wrapper */
.ka-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.ka-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
a{text-decoration:none;}